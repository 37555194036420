@tailwind base;
@tailwind components;
@tailwind utilities;



@media print {

  @page {
    margin: 0; 
    size: auto; 
  }

  body {
    margin: 0; 
    padding: 0;
    overflow: hidden; 
  }

  body * {
    visibility: hidden;
  }

  #invoice-content, #invoice-content * {
    visibility: visible;
  }


   #invoice-content {
    width: 100% !important;
    max-width: none !important;
    position: relative;
    left: 0;
    top: 0;
    page-break-inside: avoid; 
    break-inside: avoid; 
    overflow: hidden;
    page-break-before: auto;
    page-break-after: auto;
  }

  html, body {
    height: 100%;
  }

  html {
    overflow: hidden;
  }

}





.loading-indicator:before {
    content: '';
    background: #00000080;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .loading-indicator:after {
    content: ' ';
    position: fixed;
    top: 40%;
    left: 45%;
    z-index: 10010;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.2rem;        
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #0474bf; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}
.logo-animation {
  animation-duration: 30s;
}
/* //css during pdf downlode */
@layer base {img {display: initial;}}
